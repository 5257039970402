<template>
  <v-container >

     <v-row justify="center" class="ProfileEdit">
          <v-col sm="12" md='10'  >
              <div class="card row">
                <section class="card-info card-section col-sm-12 col-md-5">
                  <i class="fas fa-search search"></i>
                  
                  <!-- <div class="avatar row">
                  </div> -->
                  <v-avatar class="mb-5 " size="90" >
                    <v-img v-if="userCredentials.profile.profile_picture" class="card-img" :src="userCredentials.profile.profile_picture" :avatar="userCredentials.profile.firstname"></v-img>
                    <avatar v-else class="card-img" :username="userCredentials.profile.firstname+' '+userCredentials.profile.lastname"></avatar>
                  </v-avatar>
                  
                  <section class="user row text-center">
                    <h1 class="user-header">
                      {{userCredentials.profile.firstname}} {{userCredentials.profile.lastname}}
                      <h2 class="sub header">
                        {{userCredentials.profile.position}}
                      </h2>
                    </h1>
                  </section>
                  
                  <section class="statistics">
                    <article class="statistic">
                      <h4 class="statistic-title">
                        {{$t('public.rank')}}
                      </h4>
                      <h3 class="statistic-value">
                        #98
                      </h3>
                    </article>
                    
                    <article class="statistic">
                      <h4 class="statistic-title">
                        {{$t('public.points')}}
                      </h4>
                      <h3 class="statistic-value">
                        1,034
                      </h3>
                    </article>
                  </section>
                  
                  <div class="dial">
                    <h2 class="dial-title">
                      35
                    </h2>
                    <h3 class="dial-value">
                    {{$t('public.participations')}}
                    </h3>
                  </div>
                </section>
                <section class="card-details card-section col-sm-12 col-md-7">
                  <v-tabs
                    background-color="transparent"
                    class=" asessTabs"
                    active-class="activeAsessTab"
                    mobile-break-point='350'
                    grow
                    hide-slider
                    >
                      <v-tab class=" asessTab notstartedAssessmentsList">
                        <span class="assessments-column-header">
                          <h2>{{$t('public.department')}}</h2>
                        </span>
                      </v-tab>
                      <v-tab class=" asessTab notcompletedAssessmentsList">
                        <span class="assessments-column-header">
                          <h2>{{$t('public.company')}}</h2>
                        </span>
                      </v-tab>
                      
                      
                      <v-tab-item class="tabContainer mt-5">
                        <dl class="leaderboard">
                          <br>
                          <section class="user row text-center">
                            <h3 class="user-header brandPrimColor">
                              {{$t('public.points')}} : 3000
                            </h3>
                          </section>
                          <dt>
                            <v-progress-linear
                              value="60"
                              height="10"
                              striped
                              color="light-green darken-4"
                            ></v-progress-linear>
                          </dt>
                          <dd>
                            <div class="leaderboard-name">Ali Ahmed</div>
                            <div class="leaderboard-value">20.123</div>
                          </dd>
                          <dt>
                            <v-progress-linear
                              color="light-blue"
                              height="10"
                              value="10"
                              striped
                            ></v-progress-linear>
                          </dt>
                          <dd>
                            <div class="leaderboard-name">Othman Gamal</div>
                            <div class="leaderboard-value">16.354</div>
                          </dd>
                          <dt>
                            <v-progress-linear
                              color="light-green darken-4"
                              height="10"
                              value="20"
                              striped
                            ></v-progress-linear>
                          </dt>
                          <dd>
                            <div class="leaderboard-name">Hussien Ahmed</div>
                            <div class="leaderboard-value">15.873</div>
                          </dd>
                          <dt>
                            <v-progress-linear
                              height="10"
                              value="45"
                              striped
                              color="lime"
                            ></v-progress-linear>
                          </dt>
                          <dd>
                            <div class="leaderboard-name">Ibrahim Othman</div>
                            <div class="leaderboard-value">12.230</div>
                          </dd>
                          <dt>
                            <v-progress-linear
                              value="60"
                              height="10"
                              striped
                              color="deep-orange"
                            ></v-progress-linear>
                          </dt>
                          <dd>
                            <div class="leaderboard-name">Ahmed Hassan</div>
                            <div class="leaderboard-value">10.235</div>
                          </dd>
                        </dl>
                      </v-tab-item>
                      <v-tab-item class="tabContainer mt-5">
                        <dl class="leaderboard">
                          <br>
                          <section class="user row text-center" >
                            <h3 class="user-header brandPrimColor" >
                              {{$t('public.points')}} : 5,300
                            </h3>
                          </section>
                          <dt>
                            <v-progress-linear
                              value="60"
                              height="10"
                              striped
                              color="light-green darken-4"
                            ></v-progress-linear>
                          </dt>
                          <dd>
                            <div class="leaderboard-name">Ali Ahmed</div>
                            <div class="leaderboard-value">20.123</div>
                          </dd>
                          <dt>
                            <v-progress-linear
                              color="light-blue"
                              height="10"
                              value="10"
                              striped
                            ></v-progress-linear>
                          </dt>
                          <dd>
                            <div class="leaderboard-name">Othman Gamal</div>
                            <div class="leaderboard-value">16.354</div>
                          </dd>
                          <dt>
                            <v-progress-linear
                              color="light-green darken-4"
                              height="10"
                              value="20"
                              striped
                            ></v-progress-linear>
                          </dt>
                          <dd>
                            <div class="leaderboard-name">Hussien Ahmed</div>
                            <div class="leaderboard-value">15.873</div>
                          </dd>
                          <dt>
                            <v-progress-linear
                              height="10"
                              value="45"
                              striped
                              color="lime"
                            ></v-progress-linear>
                          </dt>
                          <dd>
                            <div class="leaderboard-name">Ibrahim Othman</div>
                            <div class="leaderboard-value">12.230</div>
                          </dd>
                          <dt>
                            <v-progress-linear
                              value="60"
                              height="10"
                              striped
                              color="deep-orange"
                            ></v-progress-linear>
                          </dt>
                          <dd>
                            <div class="leaderboard-name">Ahmed Hassan</div>
                            <div class="leaderboard-value">10.235</div>
                          </dd>
                        </dl>
                      </v-tab-item>
                  </v-tabs>
                  
                  <!-- <nav class="menu">
                    <article class="menu-item menu-item-active">
                      Global
                    </article>
                    <article class="menu-item">
                      Friends
                    </article>
                  </nav> -->
                  
                  
                </section>
              </div>
          </v-col>
      </v-row>
        

        
    </v-container>
</template>
<script>
// import i18n from '@/plugins/i18n';
import { mapGetters } from 'vuex';




export default {
    components: {
    },
    computed: {
        ...mapGetters(['userCredentials', 'isAuthenticated', 'theme', "errors"])
    },
}
</script>

<style lang="scss" scoped>
  $background: #16a8a3;
  $accent: #00a8ff;
  $margin: 40px;

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    font-weight: 300;
  }

  h1, h2 {
    font-size: 1.5em;
  }

  h2 {
    &.sub.header {
      font-size: 1em;
    }
  }
  .row {
    margin-bottom: $margin;
  }

  .card {
    display: flex;
    background: #fff;
    min-width: 50%;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 2px 4px 0 rgba(34,36,38,.12), 0 2px 10px 0 rgba(34,36,38,.15), 0 55px 50px -20px rgba(34,36,38,.25);

    .user-header {
      margin: auto;
    }

    .dial-value {
    font-size: 14px;
    width: 80px;
    height: 80px;
}

    &-section {
      flex-grow: 1;
      padding: $margin;
    }
    &-info {
      position: relative;
      background: linear-gradient(to bottom, #626d82 0%,#343d4d 100%);
      color: rgba(255, 255, 255, 0.8);
      text-align: center;
      
      h2 {
        &.sub.header {
          color: rgba(255, 255, 255, 0.3);
        }
      }
      
      .menu, .search {
        position: absolute;
        font-size: 3em;
        left: $margin;
      }
      
      .menu {
        top: $margin;
      }
      
      .search {
        bottom: $margin;
      }
    }
    
    &-details {
      position: relative;
      color: rgba(0, 0, 0, 0.6);
      width: 50%;
    }
  }

  .avatar {
    display:inline-block;
    background-image: url('http://radfaces.com/images/avatars/alex-murphy.jpg');
    background-size: cover;
    border-radius: 50%;
    height: 75px;
    width: 75px;
    border: 3px solid $accent;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.12), 0 2px 10px 0 rgba(0,0,0,.15);
  }

  .dial {
    color: $accent;
    text-shadow: inset 1px 1px 0 rgba(255, 255, 255, 1);
    background: #fff;
    border-radius: 50%;
    display: inline-block;
    height: 150px;
    margin: 50px 0;
    padding: 40px;
    box-shadow: 0 4px 15px -10px  rgba(0, 0, 0, .1), 0 5px 15px 1px rgba(0, 0, 0, .18), 0 0 0 15px #fff, 0 0 0 22px $accent, 0 55px 50px -20px rgba(34,36,38,.25);
    
    &-title {
      font-size: 3.8em;
      font-weight: 400;
      line-height: .8;
      
    }
    &-value {
      font-size: 1.8em;
    }
  }

  .menu {
    display: flex;
    justify-content: space-between;
    margin-bottom: $margin;
    &-item {
      font-size: 1.6em;
      font-weight: 400;
      padding: 20px 40px;
      color: rgba(0, 0, 0, 0.3);
      min-width: 50%;
      
      &:not(:last-child) {
        box-shadow: 25px 0px 0 -24px rgba(0, 0, 0, 0.2);
      }
      
      &:last-child {
        text-align: right;
      }
      
      &-active {
        color: tint( $accent, 20% );
      }
    }
  }

  .statistics {
    display: flex;
    justify-content: center;
    
    .statistic {
      padding: 0px 20px;
      min-width: 120px;
      &:not(:last-child) {
        box-shadow: 20px 0px 0px -19px rgba(255, 255, 255, 0.2)
      }
      
      &-title {
        font-size: 1.5em;
      }
      &-value {
        color: $accent;
        font-size: 2em;
      }
    }
  }


  .progress {
    display: flex;
    background: rgba(0, 0, 0, 0.1);
    min-height: 12px;
    overflow: hidden;
    
    &-bar {
      background: tint( $accent, 20% );
      min-height: 10px;
    }
  }

  .leaderboard {
    height: 100%;
    counter-reset: leaderboard-counter;
    
    dt {
      position: relative;
      padding-left: 40px;
      margin-bottom: 10px;
      
      &:before {
        content: counter(leaderboard-counter);
        counter-increment: leaderboard-counter;
        position: absolute;
        left: 0;
        top: 0;
        font-size: 1.3em;
        line-height: .8;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.8);
      }
    }
    
    dd {
      display: flex;
      margin-bottom: 40px;
      justify-content: space-between;
    }
    
    &-value {
      font-weight: 700;
    }
  }
    
</style>